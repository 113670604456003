import React, { FC } from 'react'

interface IBackgroundVideoProps {
  posterUrl?: string
  videoUrl: string
  className: string
  id: string
}

const BackgroundVideo: FC<IBackgroundVideoProps> = ({
  posterUrl,
  videoUrl,
  className,
  id,
}) => (
  <video
    autoPlay
    playsInline
    muted
    loop
    id={id}
    poster={posterUrl}
    className={`absolute inset-0 w-full object-cover h-full ${className}`}
  >
    <source src={videoUrl} type="video/mp4" />
  </video>
)

export default BackgroundVideo
