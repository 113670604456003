// i18next-extract-mark-ns-start home

import React from 'react'
import Layout from 'src/components/layout'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import sideMenuStore from 'src/components/side-menu/side-menu-store'
import BackgroundVideo from 'src/components/main-page/BackgroundVideo'
import { getUrl } from 'src/helpers/media'

const IndexPage = ({ pageContext: { seo, newsItem, videoTransmission } }) => {
  const { t } = useTranslation('homepage')
  const setIsSideMenuOpen = sideMenuStore((state) => state.setIsSideMenuOpen)
  const { title, description } = seo
  
  const getPlainContent = () => (
    <div className="z-10 mx-auto flex flex-col justify-center flex-wrap h-full text-brightYellow">
      <button
        type="button"
        onClick={() => setIsSideMenuOpen(true)}
        className="mb-12 xl:mb-20"
      >
        <h1 className="w-full text-6xl sm:text-8xl xl:text-9xl font-extralight tracking-wider uppercase">
          Laws
          <br />
          of the
          <br className="md:hidden" />
          <span className="hidden md:inline">&nbsp;</span>
          game
        </h1>
        <h2 className="w-full text-xl sm:text-2xl md:text-3xl xl:text-4xl font-light mt-6 tracking-widest uppercase">
          {t('Football laws')}
          <span className="hidden md:inline">&nbsp;</span>
          <br className="md:hidden" />
          {t('and regulations')}
        </h2>
      </button>
    </div>
  )

  const getTitleLine = (text) => (
    text 
      ? <>
          <br className="" />
          <span className="inline xl:hidden">&nbsp;</span>
          {text}
        </>
      : <></>
  )

  const getVideoContent = () => (
    <div className="mx-12 xl:mx-24 z-10 w-full flex flex-col justify-center flex-wrap h-full text-brightYellow items-center">
      <div
        className="flex items-center justify-center w-full flex-wrap"
        style={{ maxWidth: '1500px' }}
      >
        <div className="xl:w-1/2">
          <h1 className="text-center xl:text-left w-full text-5xl sm:text-7xl xl:text-8xl font-extralight tracking-wider uppercase">
            { videoTransmission.titleFirstLine }
            { getTitleLine(videoTransmission.titleSecondLine) }
            { getTitleLine(videoTransmission.titleThirdLine) }
          </h1>
          <h2 className="text-center xl:text-left w-full text-xl sm:text-2xl md:text-3xl xl:text-4xl font-light mt-6 tracking-widest uppercase">
            <span className="">{videoTransmission.subtitleFirstLine}</span>
            <br />
            <span className="">{videoTransmission.subtitleSecondLine}</span>
          </h2>
        </div>
        <div className="xl:w-1/2 bg-grayBlue w-full mt-6 md:mt-24 xl:mt-0">
          <iframe
            className="main-page-video-iframe"
            width="100%"
            src={`https://www.youtube-nocookie.com/embed/${videoTransmission.youtubeTransmissionId}?autoplay=1&mute=1`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
    </div>
  )

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Layout featuredNewsItem={newsItem}>
        <div className={`relative bg-mainPageBackgroundPlaceholder bg-cover bg-center md:bg-none ${videoTransmission ? "pt-10 pb-24" : "py-32"} md:py-48 xl:min-h-screen xl:flex xl:items-center min-h-0.87screen md:min-h-0.7screen flex items-center`}>
          <BackgroundVideo
            videoUrl={getUrl({ 
              url: 'https://www.datocms-assets.com/43623/1629196259-mobile-video.mp4', 
              path: '/43623/1629196259-mobile-video.mp4' 
            })}
            className="md:hidden"
            id="mobile-main-page-video"
          />
          <BackgroundVideo
            videoUrl={getUrl({
              url: 'https://www.datocms-assets.com/43623/1629196911-desktop-video.mp4', 
              path: '/43623/1629196911-desktop-video.mp4'
            })}
            className="hidden md:block"
            id="desktop-main-page-video"
          />
          { videoTransmission ? getVideoContent() : getPlainContent() }
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default IndexPage
